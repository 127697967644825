<template>
  <NavCheckAndConfirm :text="giftsDescription" :backTo="backTo">
    <template></template>
  </NavCheckAndConfirm>
</template>

<script>
import NavCheckAndConfirm from '@/common/ui/NavCheckAndConfirm'
import { willStringHelpers } from '@/common/mixins/willStringHelpers'
import { sectionDescriptions } from '@/common/mixins/sectionDescriptions'

export default {
  name: 'GiftsCheckAndConfirm',
  mixins: [willStringHelpers, sectionDescriptions],
  components: {
    NavCheckAndConfirm
  },
  computed: {
    details() {
      return this.$store.getters.details
    },
    partner() {
      return this.$store.getters.partner
    },
    backTo() {
      if (!this.details.gifts) return '/gifts/leave_option'
      else if (!this.partner) return '/gifts/direct'
      else if (this.partner && this.details.gifts_after)
        return '/gifts/indirect'
      else return '/gifts/indirect_option'
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch('saveDetails')
    this.$notification.success('Gifts preferences saved')
    if (!this.details.gifts) {
      this.$store.dispatch('giftBeforeClear')
      this.$store.dispatch('giftAfterClear')
    } else {
      if (!this.details.gifts_before) this.$store.dispatch('giftBeforeClear')
      if (!this.details.gifts_after) this.$store.dispatch('giftAfterClear')
    }
    next()
  }
}
</script>

<style></style>
